import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`1) When logged in to the management portal, click the “New activity” button.
Drag and drop a .gpx file from your computer into the map of the editor:`}</p>
    <Image src="pro/drag-drop-gpx.jpg" mdxType="Image" />
    <br />
    <p>{`2) Use the slider under the map to cut the start and end of the track.
You can also reverse the direction:`}</p>
    <Image src="pro/crop-start-stop.jpg" mdxType="Image" />
    <br />
    <p>{`3) Click on the drop downs to select the activity type, difficulty level,
and visibility. Type in a name and description:`}</p>
    <Image src="pro/select-activity-type.jpg" mdxType="Image" />
    <br />
    <p>{`4) Click on the attributes to select them. Enter keywords to optimize the
searchability and to easily create custom maps:`}</p>
    <Image src="pro/select-attributes.jpg" mdxType="Image" />
    <p>{`Don’t forget to click on “Save”. To see the track in the app,
refresh the trailguide.net app once (or twice, and remember to select the right
category).`}</p>
    <br />
    <p>{`If you want to create multiple activities out of one recorded GPS track,
click on “copy”. The activity name changes to “Copy of …” but the rest stays
the same, so you don’t have to enter everything again.`}</p>
    <Image src="pro/copy.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/login" mdxType="Link">
  ← Log in to the management portal
    </Link>
    <Link className="float-right" to="/pro/activitytype" mdxType="Link">
  Select the type of activity →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      